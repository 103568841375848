import { useEffect, useState, useCallback } from "react";
import Game from "./Game";
import InitGame from "./InitGame";
import socket from "./socket";
import UsernameDialog from "./components/UsernameDialog";
import Header from "./components/Header";
import JoinGame from "./components/JoinGame";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

export default function App() {
  const [username, setUsername] = useState("");
  const [usernameSubmitted, setUsernameSubmitted] = useState(false);
  const [room, setRoom] = useState("");
  const [orientation, setOrientation] = useState("");
  const [players, setPlayers] = useState([]);

  const cleanup = useCallback(() => {
    setRoom("");
    setOrientation("");
    setPlayers([]);
  }, []);

  useEffect(() => {
    socket.on("opponentJoined", (roomData) => {
      console.log("roomData", roomData);
      setPlayers(roomData.players);
    });
  }, []);

  return (
    <div class="w-full min-h-screen relative bg-front-text2">
      <Header />
      <RouterProvider
        router={createBrowserRouter([
          {
            path: "/join/:room",
            element: (
              <JoinGame
                setRoom={setRoom}
                setOrientation={setOrientation}
                setPlayers={setPlayers}
                usernameSubmitted={usernameSubmitted}
                setUsername={setUsername}
                setUsernameSubmitted={setUsernameSubmitted}
                cleanup={cleanup}
              />
            ),
          },
          {
            path: "/",
            element: (
              <>
                {usernameSubmitted && !room && (
                  <div class="w-full flex justify-center items-center ">
                    <InitGame
                      setRoom={setRoom}
                      setOrientation={setOrientation}
                      setPlayers={setPlayers}
                    />
                  </div>
                )}
                {room && (
                  <div>
                    <Game
                      room={room}
                      orientation={orientation}
                      username={username}
                      players={players}
                      cleanup={cleanup}
                    />
                  </div>
                )}
              </>
            ),
          },
        ])}
      />

      {!usernameSubmitted && (
        <div class="w-full h-screen flex justify-center items-center">
          <UsernameDialog
            open={!usernameSubmitted}
            username={username}
            setUsername={setUsername}
            onSubmit={() => setUsernameSubmitted(true)}
          />
        </div>
      )}
    </div>
  );
}
