import React from "react";
import { TextField } from "@mui/material";
import CustomDialog from "./CustomDialog";
import socket from "../socket";

export default function UsernameDialog({ open, username, setUsername, onSubmit }) {
  return (
    <CustomDialog
      open={open}
      handleClose={onSubmit}
      title="Pick a username"
      contentText="Please select a username"
      handleContinue={() => {
        if (!username) return;
        socket.emit("username", username);
        onSubmit();
      }}
    >
      <TextField
        autoFocus
        margin="dense"
        id="username"
        label="Username"
        name="username"
        value={username}
        required
        onChange={(e) => setUsername(e.target.value)}
        type="text"
        fullWidth
        variant="standard"
      />
    </CustomDialog>
  );
}