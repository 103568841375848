import { TextField } from "@mui/material";
import { useState } from "react";
import CustomDialog from "./components/CustomDialog";
import socket from "./socket";

export default function InitGame({ setRoom, setOrientation, setPlayers }) {
  const [roomDialogOpen, setRoomDialogOpen] = useState(false);
  const [roomInput, setRoomInput] = useState(""); // input state
  const [roomError, setRoomError] = useState("");

  return (
    <div className="w-1/2 max-w-80 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
      <CustomDialog
        open={roomDialogOpen}
        handleClose={() => setRoomDialogOpen(false)}
        title="Select Room to Join"
        contentText="Enter a valid room ID to join the room"
        handleContinue={() => {
          // join a room
          if (!roomInput) return; // if given room input is valid, do nothing.

          socket.emit("joinRoom", { roomId: roomInput }, (r) => {
            // r is the response from the server
            if (r.error) return setRoomError(r.message); // if an error is returned in the response set roomError to the error message and exit
            console.log("response:", r);
            setRoom(r?.roomId); // set room to the room ID
            setPlayers(r?.players); // set players array to the array of players in the room
            setOrientation(r?.side ?? "black"); // set orientation as black
            setRoomDialogOpen(false); // close dialog
          });
        }}
      >
        <TextField
          autoFocus
          margin="dense"
          id="room"
          label="Room ID"
          name="room"
          value={roomInput}
          required
          onChange={(e) => setRoomInput(e.target.value)}
          type="text"
          fullWidth
          variant="standard"
          error={Boolean(roomError)}
          helperText={
            !roomError ? "Enter a room ID" : `Invalid room ID: ${roomError}`
          }
        />
      </CustomDialog>

      {/* Button for starting a game */}
      <button
        className="w-full   bg-front-primary hover:bg-front-primaryHover transition  py-2 rounded-sm mb-2 text-2xl"
        variant="contained"
        onClick={() => {
          socket.emit("createRoom", (room, side) => {
            console.log(room);
            setRoom(room);
            setOrientation(side ?? "white");
          });
        }}
      >
        Start a game
      </button>
      {/* Button for joining a game */}
      <button
        className="w-full border border-front-primary hover:bg-front-primaryHover transition color-front-text2 py-2 rounded-sm text-2xl"
        onClick={() => {
          setRoomDialogOpen(true);
        }}
      >
        Join a game
      </button>
    </div>
  );
}
