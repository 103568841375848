import React from "react";
import ClipboardCopy from "./ClipboardCopy";

export default function RoomInfo({ room }) {
  return (
    <div class="w-full">
        <h2 className="font-bold text-xl">Current Room:</h2>
        <ClipboardCopy text={`${room}`} />

      {/*<p>Invite your friend:</p>
      <ClipboardCopy text={`http://localhost:3000/join/${room}`} />*/}
    </div>
  );
}
