import { useState, useMemo, useCallback, useEffect } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import CustomDialog from "./components/CustomDialog";
import socket from "./socket";
import RoomInfo from "./components/RoomInfo";

function Game({ players, room, orientation, cleanup }) {
  const chess = useMemo(() => new Chess(), []);
  const [fen, setFen] = useState(chess.fen());
  const [over, setOver] = useState("");
  const [message, setMessage] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [moveFrom, setMoveFrom] = useState("");
  const [optionSquares, setOptionSquares] = useState({});

  const sendGamoverEmit = useCallback(
    (data) => {
      if (orientation === "black") return;
      socket.emit("gameOver", data);
    },
    [orientation]
  );
  const makeAMove = useCallback(
    (move) => {
      try {
        const result = chess.move(move);
        setFen(chess.fen());
        setMessage("");

        if (chess.isGameOver()) {
          if (chess.isCheckmate()) {
            sendGamoverEmit({
              room,
              draw: false,
              winner: chess.turn() === "w" ? "white" : "black",
            });
            setOver(
              `Checkmate! ${chess.turn() === "w" ? "black" : "white"} wins!`
            );
          } else if (chess.isDraw()) {
            sendGamoverEmit({ room, draw: true });
            setOver("Draw");
          } else {
            sendGamoverEmit({ room, draw: false, winner: null });
            setOver("Game over");
          }
        }

        return result;
      } catch (e) {
        return null;
      }
    },
    [chess, sendGamoverEmit, room]
  );

  const getCurrentPlayer = () => {
    const currentTurn = chess.turn(); // "w" or "b"
    const [WHITE, BLACK] = ["w", "b"];

    return currentTurn === WHITE ? players[0].username : players[1].username;
  };

  // onDrop function
  function onDrop(sourceSquare, targetSquare) {
    // orientation is either 'white' or 'black'. game.turn() returns 'w' or 'b'
    if (chess.turn() !== orientation[0]) {
      // show message that its not your turn
      setMessage("It's not your turn");
      return false; // <- 1 prohibit player from moving piece of other player
    }

    if (players.length < 2) return false; // <- 2 disallow a move if the opponent has not joined

    const moveData = {
      from: sourceSquare,
      to: targetSquare,
      color: chess.turn(),
      promotion: "q", // promote to queen where possible
    };

    const move = makeAMove(moveData);

    // illegal move
    if (move === null) return false;

    socket.emit("move", {
      // <- 3 emit a move event.
      move,
      room,
    }); // this event will be transmitted to the opponent via the server

    setMoveFrom("");
    setOptionSquares({});

    return true;
  }

  // Function to get and display move options
  function getMoveOptions(square) {
    const moves = chess.moves({ square, verbose: true });
    if (moves.length === 0) {
      setOptionSquares({});
      return false;
    }
    const newSquares = {};
    moves.forEach((move) => {
      newSquares[move.to] = {
        background:
          chess.get(move.to) &&
          chess.get(move.to).color !== chess.get(square).color
            ? "radial-gradient(circle, rgba(0,0,0,.1) 85%, transparent 85%)"
            : "radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)",
        borderRadius: "50%",
      };
    });
    newSquares[square] = { background: "#fdcd59" };
    setOptionSquares(newSquares);
    return true;
  }

  // Handle click-to-move functionality
  function onSquareClick(square) {
    // Clear previous right-clicked or option squares
    setMessage("");

    // Selecting the piece to move from
    if (!moveFrom) {
      const hasMoveOptions = getMoveOptions(square);
      if (hasMoveOptions) setMoveFrom(square);
      return;
    }

    // Move to the target square
    const move = makeAMove({
      from: moveFrom,
      to: square,
      promotion: "q", // Promote to queen by default
    });

    if (move === null) {
      // Invalid move, allow selecting a new piece
      const hasMoveOptions = getMoveOptions(square);
      setMoveFrom(hasMoveOptions ? square : "");
      return;
    }

    // Emit move to the server
    socket.emit("move", { move, room });

    // Reset moveFrom and optionSquares after a valid move
    setMoveFrom("");
    setOptionSquares({});
  }

  useEffect(() => {
    socket.on("move", (move) => {
      makeAMove(move);
    });
  }, [makeAMove]);

  useEffect(() => {
    socket.on("playerDisconnected", (player) => {
      setOver(`${player.username} has disconnected`);
    });
  }, []);

  useEffect(() => {
    socket.on("closeRoom", ({ roomId }) => {
      if (roomId === room) {
        cleanup();
      }
    });
  }, [room, cleanup]);

  useEffect(() => {
    socket.on("gameStart", ({ startTime }) => {
      setStartTime(startTime);
    });

    return () => {
      socket.off("gameStart");
    };
  }, []);

  useEffect(() => {
    if (startTime) {
      const interval = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startTime]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="flex justify-center items-center relative">
      <div>
        {players.length < 2 ? (
          <div>
            <RoomInfo room={room} />
          </div>
        ) : (
          <div className="mx-auto">
            <h2 className="mt-8 flex justify-center items-center gap-2">
              <span className="text-4xl font-bold">{players[0].username}</span>

              <svg
                className="max-w-8"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 225.1 378.9"
              >
                <path
                  d="M165.9 13.9c-13.4 50-26.7 100-40.1 150 31-1.4 62-2.7 92.9-4.1L59.8 365.3C73 315.7 86.2 266 99.3 216.4c-31 2.4-61.9 4.8-92.9 7.1L165.9 13.9z"
                  fill="#fff"
                  stroke="#f6cf6e"
                  stroke-width="25"
                />
              </svg>

              <span className="text-4xl font-bold">{players[1].username}</span>
            </h2>

            <div className="absolute bottom-10 left-5">
              {startTime && <p>Game Time: {formatTime(elapsedTime)}</p>}

              <p>Current Turn: {getCurrentPlayer()}</p>
            </div>
          </div>
        )}

        <div
          className="board max-w-[90vh] w-[90vw] md:max-w-[80vh] md:w-[80vw] lg:max-w-[70vh] lg:w-[70vw]"
          style={{
            margin: "3rem auto",
          }}
        >
          <Chessboard
            position={fen}
            onSquareClick={onSquareClick}
            onPieceDrop={onDrop}
            boardOrientation={orientation}
            customBoardStyle={{
              borderRadius: "4px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.5)",
            }}
            customDarkSquareStyle={{
              backgroundColor: "#FFE5A7",
            }}
            customLightSquareStyle={{
              backgroundColor: "#fff",
            }}
            customSquareStyles={optionSquares}
          />
        </div>

        {message && <p>{message}</p>}
      </div>

      <CustomDialog
        open={Boolean(over)}
        title={over}
        contentText={over}
        handleContinue={() => {
          socket.emit("closeRoom", { roomId: room });
          cleanup();
        }}
      />
    </div>
  );
}

export default Game;
